import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { message } from 'antd';
import axios from 'axios';
import api from 'services/api';

export interface City {
  id: string;
  city: string;
  state: string;
}

export interface CitiesContextData {
  cities: City[];
  selectedCity: string | null;
  selectedState: string | null;
  handleSelectCity(city: string): void;
}

const CitiesContext = createContext<CitiesContextData>({} as CitiesContextData);

const CitiesProvider: React.FC = ({ children }) => {
  const [cities, setCities] = useState<City[]>([]);
  const [selectedCity, setSelectedCity] = useState(() => {
    const selectedCity = localStorage.getItem('@folhetonamao:city');

    if (selectedCity) {
      return selectedCity;
    }

    return null;
  });
  const [selectedState, setSelectedState] = useState(() => {
    const selectedState = localStorage.getItem('@folhetonamao:state');

    if (selectedState) {
      return selectedState;
    }

    return null;
  });

  const handleGetCities = useCallback(async () => {
    try {
      const { data } = await api.get('shop/cities');

      setCities(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, []);

  const handleSelectCity = useCallback((city: string) => {
    const cityInfo = city.split(',');

    localStorage.setItem('@folhetonamao:city', cityInfo[0]);
    localStorage.setItem('@folhetonamao:state', cityInfo[1].replace(' ', ''));
    setSelectedCity(cityInfo[0]);
    setSelectedState(cityInfo[1].replace(' ', ''));
  }, []);

  useEffect(() => {
    handleGetCities();
  }, []);

  return (
    <CitiesContext.Provider
      value={{
        cities,
        selectedCity,
        selectedState,
        handleSelectCity,
      }}
    >
      {children}
    </CitiesContext.Provider>
  );
};

function useCities(): CitiesContextData {
  const context = useContext(CitiesContext);

  return context;
}

export { CitiesProvider, useCities };
