import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { Flyer, Heading } from 'components';
import { Layout } from 'containers';
import { useCities } from 'hooks';
import { FlyerModel } from 'models';
import api from 'services/api';

import * as S from './styles';

export function Flyers() {
  const { selectedCity, selectedState } = useCities();
  const [lastFlyers, setLastFlyers] = useState<FlyerModel[]>([]);

  const handleGetLastFlyers = useCallback(async () => {
    try {
      const { data } = await api.get(
        `flyer?state=${selectedState}&city=${selectedCity}`,
      );

      setLastFlyers(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, [selectedState, selectedCity]);

  useEffect(() => {
    handleGetLastFlyers();
  }, [selectedState, selectedCity]);

  return (
    <Layout>
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Folhetos</Heading>
            <S.SectionSubtitle>
              As melhores ofertas do momento perto de você
            </S.SectionSubtitle>
          </div>
        </S.SectionHeader>
        <S.FlyerContainer>
          {lastFlyers.map(flyer => (
            <Flyer key={flyer.id} data={flyer} />
          ))}
        </S.FlyerContainer>
      </S.Section>
    </Layout>
  );
}
