import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import { Plan } from 'components';
import { Layout } from 'containers';
import api from 'services/api';

import { PlanModel } from './interfaces';
import * as S from './styles';

export function Plans() {
  const [plans, setPlans] = useState<PlanModel[]>([]);

  const handleGetPlans = useCallback(async () => {
    try {
      const { data } = await api.get('plan');

      setPlans(data);
    } catch (err) {
      message.error('Não foi possível carregar os planos');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetPlans();
  }, []);

  return (
    <Layout>
      {/* <S.Container>
        <h1>Planos</h1>
        <span>Escolha o plano perfeito para o seu negócio</span>

        <S.PlansContainer>
          {plans.map(plan => (
            <Plan
              key={plan.id}
              id={plan.id}
              name={plan.title}
              price={plan.price}
              items={[
                { text: `Até ${plan.flyers_limit} folhetos no mês` },
                { text: 'Notificações' },
                {
                  text: 'Evidência do logotipo',
                  disabled: plan.shops_limit === 1,
                },
                {
                  text: 'Mais de uma loja por conta',
                  disabled: plan.shops_limit === 1,
                },
              ]}
            />
          ))}
        </S.PlansContainer>
      </S.Container> */}
    </Layout>
  );
}
