import { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Heading, Textfield } from 'components';
import { Layout } from 'containers';
import { useUser } from 'hooks';

import * as S from './styles';

type Inputs = {
  password: string;
};

export function CreateNewPassword() {
  const { handleSubmit, control } = useForm<Inputs>();
  const { createNewPassword, user } = useUser();
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (token) {
      createNewPassword(token, data.password);
    }
  };

  useEffect(() => {
    new URL(location.href).searchParams.get('token');
    const params = new URL(location.href).searchParams;
    const resetToken = params.get('token');

    if (user || !resetToken) {
      return navigate('/');
    }

    setToken(resetToken);
  }, [user]);

  return (
    <Layout>
      <S.Section>
        <Heading level={3}>Redefinir senha</Heading>
        <S.SectionSubtitle>
          Para redefinir sua senha, informe seu e-mail de cadastro
        </S.SectionSubtitle>
      </S.Section>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => (
            <Textfield
              {...field}
              required
              type="password"
              placeholder="Nova senha"
            />
          )}
          control={control}
          name="password"
        />

        <Button type="submit" size="large">
          Redefinir
        </Button>
      </S.Form>
    </Layout>
  );
}
