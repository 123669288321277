/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useEffect, useState } from 'react';
import { BiUserPlus } from 'react-icons/bi';
import {
  MdExpandMore,
  MdOutlinePlace,
  MdOutlineHome,
  MdPerson,
  MdShoppingCart,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { DownOutlined } from '@ant-design/icons';
import { Select, Affix, Avatar, Menu, Dropdown } from 'antd';
import horizontalLogo from 'common/assets/svgs/logo/horizontal.svg';
import { Button } from 'components';
import { useUser, useCities } from 'hooks';

import * as S from './styles';

export function Header() {
  const { user, signOut } = useUser();
  const { cities, handleSelectCity, selectedCity } = useCities();

  const [currentPage, setCurrentPage] = useState('/');
  const navigate = useNavigate();

  const handleGetCurrentPage = useCallback(() => {
    setCurrentPage(window.location.pathname);
  }, [window]);

  useEffect(() => {
    handleGetCurrentPage();
  }, []);

  return (
    <S.Container>
      <S.Content>
        <img
          src={horizontalLogo}
          alt="Folheto na mão"
          onClick={() => navigate('/')}
        />
        {user ? (
          <S.UserContainer>
            <Avatar
              style={{ color: '#ff4c00', backgroundColor: '#fde3cf' }}
              size="small"
            >
              {user.name.substring(0, 1)}
            </Avatar>
            <S.UserName>{user.name}</S.UserName>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => signOut()} style={{ width: 200 }}>
                    Sair
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <DownOutlined style={{ cursor: 'pointer', fontSize: 10 }} />
            </Dropdown>
          </S.UserContainer>
        ) : (
          <S.ButtonContainer>
            <Button
              buttonType="tertiary"
              onClick={() =>
                (window.location.href = 'https://admin.folhetonamao.com.br/')
              }
              icon={<MdShoppingCart />}
              iconDirection="right"
            >
              EMPRESA ANUNCIANTE
            </Button>
            <Button
              buttonType="tertiary"
              onClick={() => navigate('/acessar')}
              icon={<MdPerson />}
              iconDirection="right"
            >
              USUÁRIO
            </Button>
            <Button
              buttonType="secondary"
              size="large"
              icon={<BiUserPlus />}
              iconDirection="right"
              onClick={() => navigate('/cadastrar')}
            >
              Cadastrar
            </Button>
          </S.ButtonContainer>
        )}
      </S.Content>
      <Affix>
        <S.NavContainer>
          <S.Nav>
            <S.NavItem
              selected={currentPage === '/'}
              onClick={() => navigate('/')}
            >
              <MdOutlineHome />
              <span>Início</span>
            </S.NavItem>
            <S.NavItem
              selected={currentPage === '/ofertas'}
              onClick={() => navigate('/ofertas')}
            >
              <span>Folhetos</span>
            </S.NavItem>
            <S.NavItem
              selected={currentPage === '/supermercados'}
              onClick={() => navigate('/supermercados')}
            >
              <span>Supermercados</span>
            </S.NavItem>
            {user && (
              <S.NavItem
                selected={currentPage === '/favoritos'}
                onClick={() => navigate('/favoritos')}
              >
                <span>Favoritos</span>
              </S.NavItem>
            )}
          </S.Nav>
          <S.Options>
            <MdOutlinePlace />
            <Select
              defaultValue={
                selectedCity ? selectedCity : 'Selecione sua cidade...'
              }
              suffixIcon={<MdExpandMore />}
              bordered={false}
              onChange={value => {
                handleSelectCity(value);
                navigate('/');
              }}
              value={selectedCity}
            >
              {cities.map(city => (
                <Select.Option
                  key={city.city}
                  value={`${city.city}, ${city.state}`}
                >
                  {city.city}
                </Select.Option>
              ))}
            </Select>
          </S.Options>
        </S.NavContainer>
      </Affix>
    </S.Container>
  );
}
