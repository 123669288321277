import { BackTop } from 'antd';
import { Drawer, Footer, Header } from 'components';

import { LayoutProps } from './interfaces';
import * as S from './styles';

export function Layout({ children }: LayoutProps) {
  return (
    <S.Container>
      <S.HeaderContainer>
        <Header />
      </S.HeaderContainer>

      <S.DrawerContainer>
        <Drawer />
      </S.DrawerContainer>
      <S.Content>{children}</S.Content>
      <Footer />
      <BackTop />
    </S.Container>
  );
}
