import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import { Heading, Plan } from 'components';
import { Layout } from 'containers';
import api from 'services/api';

import { PlanModel } from './interfaces';
import * as S from './styles';

export function Advertisers() {
  const [plans, setPlans] = useState<PlanModel[]>([]);

  const handleGetPlans = useCallback(async () => {
    try {
      const { data } = await api.get('plan');

      setPlans(data);
    } catch (err) {
      message.error('Não foi possível carregar os planos');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleGetPlans();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>Anunciantes</Heading>

        <p>
          Se você tem um supermercado e quer aumentar a suas visualizações na
          internet ou iniciar mais uma forma de divulgar seus folhetos{' '}
          <b>FOLHETO NA MÃO</b> é para você! Por conta da visibilidade do nosso
          site, com um baixo investimento você poderá ter excelentes resultados,
          vinculando os seus folhetos e ofertas diretamente aos clientes que
          buscam economizar motivando os usuários a se direcionarem a seus sites
          ou lojas físicas, para usufruírem de suas melhores promoções.
          Ajudaremos você, varejista ou atacadista, a resolver o desafio do
          mundo digital oferecendo soluções inovadoras para levar até os seus
          clientes as suas promoções acrescentando assim, de forma econômica,
          mais uma forma de divulgar e alcançar mais clientes para o seu
          supermercado.
        </p>
        <br></br>

        {/* <img src="./static/images/plans.png" alt="plans" /> */}

        <Heading level={5}>Como fazemos isso?</Heading>
        <p>
          <b>PANFLETOS DIGITAIS INTERATIVOS:</b>
          <br></br> Encorajamos a ACRESCENTAREM COMO FORMA DE PUBLICIDADE, além
          dos tradicionais folhetos promocionais de papel, a versão digital
          interativa que é mais rápida e eficaz. Esta versão também traz um
          formato inovador e dinâmico, com um conteúdo envolvente que garante
          uma melhor experiência nas compras dos consumidores. <br></br>
          <br></br> <b>ENVIO DE NOTIFICAÇÕES:</b>
          <br></br> Melhore suas vendas interagindo com os usuários mais
          próximos aos seus pontos de vendas, vinculando campanhas de ofertas
          que serão enviadas através de notificações capazes de promover ao
          consumidor ofertas personalizadas e em tempo real.
        </p>
        <br />
        {/* <Heading level={5}>Planos</Heading>
        <S.PlansContainer>
          {plans.map(plan => (
            <Plan
              key={plan.id}
              id={plan.id}
              name={plan.title}
              price={plan.price}
              items={[
                { text: `Até ${plan.flyers_limit} folhetos no mês` },
                { text: 'Notificações' },
                {
                  text: 'Evidência do logotipo',
                  disabled: plan.shops_limit === 1,
                },
                {
                  text: 'Mais de uma loja por conta',
                  disabled: plan.shops_limit === 1,
                },
              ]}
            />
          ))}
        </S.PlansContainer> */}
      </S.Container>
    </Layout>
  );
}
