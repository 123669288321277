/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback, useState } from 'react';
import { IoMenuOutline } from 'react-icons/io5';
import {
  MdOutlineHome,
  MdOutlinePlace,
  MdPerson,
  MdShoppingCart,
} from 'react-icons/md';
import ReactModernDrawer from 'react-modern-drawer';
import { useNavigate } from 'react-router-dom';
import 'react-modern-drawer/dist/index.css';

import { DownOutlined } from '@ant-design/icons';
import { Affix, Avatar, Dropdown, Menu, Select } from 'antd';
import horizontalLogo from 'common/assets/svgs/logo/horizontal.svg';
import { Button } from 'components';
import { useCities, useUser } from 'hooks';

import * as S from './styles';

export function Drawer() {
  const [isOpen, setIsOpen] = useState(false);
  const { user, signOut } = useUser();
  const { cities, handleSelectCity, selectedCity } = useCities();
  const navigate = useNavigate();

  const handleOpenDrawer = useCallback(() => {
    setIsOpen(prev => !prev);
  }, []);

  return (
    <>
      <Affix>
        <S.Container>
          <img
            src={horizontalLogo}
            alt="Folheto na mão"
            onClick={() => navigate('/')}
          />
          <div>
            <S.Options>
              <Select
                defaultValue={
                  selectedCity ? selectedCity : 'Selecione sua cidade...'
                }
                suffixIcon={<MdOutlinePlace />}
                bordered={false}
                onChange={value => {
                  handleSelectCity(value);
                  navigate('/');
                }}
                value={selectedCity}
              >
                {cities.map(city => (
                  <Select.Option
                    key={city.city}
                    value={`${city.city}, ${city.state}`}
                  >
                    {city.city}
                  </Select.Option>
                ))}
              </Select>
            </S.Options>
            <IoMenuOutline onClick={() => handleOpenDrawer()} size={28} />
          </div>
        </S.Container>
      </Affix>

      <ReactModernDrawer
        open={isOpen}
        onClose={handleOpenDrawer}
        direction="right"
      >
        <S.Content>
          <S.Nav>
            <S.NavItem onClick={() => navigate('/')}>
              <MdOutlineHome />
              <span>Início</span>
            </S.NavItem>
            <S.NavItem onClick={() => navigate('/ofertas')}>
              <span>Folhetos</span>
            </S.NavItem>
            <S.NavItem onClick={() => navigate('/supermercados')}>
              <span>Supermercados</span>
            </S.NavItem>
            {user && (
              <S.NavItem onClick={() => navigate('/favoritos')}>
                <span>Favoritos</span>
              </S.NavItem>
            )}
          </S.Nav>

          {user ? (
            <S.UserContainer>
              <div>
                <Avatar
                  style={{ color: '#ff4c00', backgroundColor: '#fde3cf' }}
                  size="small"
                >
                  {user.name.substring(0, 1)}
                </Avatar>
                <S.UserName>{user.name}</S.UserName>
              </div>

              <Menu>
                <Menu.Item
                  onClick={() => signOut()}
                  style={{ margin: '10px -10px 0' }}
                >
                  Sair
                </Menu.Item>
              </Menu>

              {/* <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item onClick={() => signOut()} style={{ width: 200 }}>
                      Sair
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
              >
                <DownOutlined style={{ cursor: 'pointer', fontSize: 10 }} />
              </Dropdown> */}
            </S.UserContainer>
          ) : (
            <S.ButtonContainer>
              <h5>Acessar</h5>

              <Button
                buttonType="tertiary"
                onClick={() => navigate('/acessar')}
                icon={<MdPerson />}
                iconDirection="right"
              >
                USUÁRIO
              </Button>

              <Button
                buttonType="tertiary"
                onClick={() =>
                  (window.location.href = 'https://admin.folhetonamao.com.br/')
                }
                icon={<MdShoppingCart />}
                iconDirection="right"
              >
                EMPRESA ANUNCIANTE
              </Button>

              {/* <Button
                buttonType="tertiary"
                size="large"
                icon={<MdPerson />}
                iconDirection="right"
                onClick={() => navigate('/cadastrar')}
              >
                Cadastrar
              </Button> */}
            </S.ButtonContainer>
          )}
        </S.Content>
      </ReactModernDrawer>
    </>
  );
}
