import { createGlobalStyle } from 'styled-components';

import { font, primaryColors, secondaryColors } from './theme';

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%; // 16px = 1.6rem
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-size: 1.6rem;
  }

  body, input, textarea, button {
    font-family: ${font.family.Inter};
    font-weight: ${font.regular};
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: ${font.semiBold};
  }

  span {
    font-size: ${font.sizes.normal};
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ::-webkit-scrollbar-track {
    background-color: ${primaryColors.white};
  }

  ::-webkit-scrollbar {
    width: 2px;
    background: ${primaryColors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: ${secondaryColors.blue100};
  }

  .ant-select-selection-item {
    color: ${primaryColors.white};
    font-size: 1.4rem;
    font-weight: ${font.medium};
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    color: ${secondaryColors.grey200};
    font-size: 1.4rem;
    font-weight: ${font.medium};
  }

  .ant-select-item-option {
    color: ${secondaryColors.grey200};
    font-size: 1.4rem;
    font-weight: ${font.medium};
  }

  .ant-select-dropdown {
    width: 500px;
  }

  .ant-select-selector {
    width: 10px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${secondaryColors.blue100};
    color: #fff
  }

  .ant-back-top-content {
    background-color: rgba(255, 76, 0, 0.45);
  }

  .ant-back-top-content:hover {
    background-color: rgba(255, 76, 0, 0.8);
  }

  .modal-content {
    background: #fff;
    width: 650px;
    height: 320px;
    margin: auto auto;
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 3px 12px #00000029;
    z-index: 999;
  }

  .modal-overlay {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    z-index: 999;
  }
`;
