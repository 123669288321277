import { breakpoints } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  img {
    width: 100%;
    margin-bottom: 6.4rem;
  }
`;

export const PlansContainer = styled.div`
  margin-top: 5.2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.2rem;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;
