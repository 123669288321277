import {
  font,
  primaryColors,
  secondaryColors,
  breakpoints,
} from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 20.2rem;
  width: 100%;
  cursor: pointer;

  a {
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: ${font.medium};
    color: ${secondaryColors.green100};
    margin-top: -0.5rem;
    display: block;

    @media (max-width: ${breakpoints.small}) {
      font-size: 1.3rem;
    }
  }
`;

export const OpenButton = styled.div`
  position: absolute;
  width: 100%;
  height: 30px;

  background-color: ${secondaryColors.orange100};

  color: #fff;
  align-items: center;
  justify-content: center;
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 26.9rem;

  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${OpenButton} {
    display: none;
  }

  :hover {
    ${OpenButton} {
      display: flex;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    margin-bottom: 1rem;
    height: 23rem;
  }
`;

export const Image = styled.img`
  max-width: 20.2rem;
  max-height: 26.9rem;
  height: 100%;
  width: 100%;

  border-radius: 0.4rem;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    height: 100%;
  }
`;

export const StoreName = styled.span`
  display: block;
  font-weight: ${font.bold};
  font-size: 1.8rem;
  color: ${primaryColors.black};

  @media (max-width: ${breakpoints.small}) {
    margin-top: 0em;
    font-size: 1.5rem;
  }
`;
