import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { Heading, Store } from 'components';
import { Layout } from 'containers';
import { useCities } from 'hooks';
import { StoreModel } from 'models';
import api from 'services/api';

import * as S from './styles';

export function Supermarket() {
  const { selectedCity, selectedState } = useCities();
  const [stores, setStores] = useState<StoreModel[]>([]);

  const handleGetStores = useCallback(async () => {
    try {
      const { data } = await api.get(
        `shop?state=${selectedState}&city=${selectedCity}`,
      );

      setStores(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, [selectedCity, selectedState]);

  useEffect(() => {
    handleGetStores();
  }, [selectedCity, selectedState]);

  return (
    <Layout>
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Supermercados</Heading>
            <S.SectionSubtitle>
              Os melhores supermercados da sua região
            </S.SectionSubtitle>
          </div>
        </S.SectionHeader>
        <S.FlyerContainer>
          {stores.map(store => (
            <Store key={store.id} data={store} />
          ))}
        </S.FlyerContainer>
      </S.Section>
    </Layout>
  );
}
