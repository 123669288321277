import { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Heading, Textfield } from 'components';
import { Layout } from 'containers';
import { useUser } from 'hooks';

import * as S from './styles';

type Inputs = {
  email: string;
};

export function ForgotPassword() {
  const { handleSubmit, control } = useForm<Inputs>();
  const { resetPassword, user } = useUser();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => resetPassword(data.email);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <Layout>
      <S.Section>
        <Heading level={3}>Redefinir senha</Heading>
        <S.SectionSubtitle>
          Para redefinir sua senha, informe seu e-mail de cadastro
        </S.SectionSubtitle>
      </S.Section>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => (
            <Textfield {...field} required type="email" placeholder="Email" />
          )}
          control={control}
          name="email"
        />

        <Button type="submit" size="large">
          Redefinir
        </Button>
      </S.Form>
    </Layout>
  );
}
