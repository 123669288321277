import styled from 'styled-components';

export const Container = styled.div`
  div > {
    p {
      &.item {
        display: block;
        margin: 0px;
      }
    }

    p {
      margin-top: 30px;
    }
  }
`;
