import {
  font,
  primaryColors,
  secondaryColors,
  breakpoints,
} from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  max-width: 20.2rem;
  width: 100%;
  cursor: pointer;

  a {
    text-decoration: none;
    font-size: 1.4rem;
    font-weight: ${font.medium};
    color: ${secondaryColors.green100};
    margin-top: -0.5rem;
    display: block;

    @media (max-width: ${breakpoints.small}) {
      font-size: 1.1rem;
    }
  }
`;

export const Image = styled.img`
  width: 20.2rem;
  height: 13.5rem;

  border-radius: 0.4rem;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    height: auto;
  }
`;

export const StoreName = styled.span`
  display: block;
  font-weight: ${font.bold};
  font-size: 1.8rem;
  color: ${primaryColors.black};

  margin-top: 1rem;

  @media (max-width: ${breakpoints.small}) {
    margin-top: 0.5rem;
    font-size: 1.3rem;
  }
`;

export const BookmarkButton = styled.button`
  position: absolute;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: ${primaryColors.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 0.5rem;
  right: 0.5rem;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${secondaryColors.red100};
  }
`;
