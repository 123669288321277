import { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Heading, Textfield } from 'components';
import { Layout } from 'containers';
import { useUser } from 'hooks';

import * as S from './styles';

type Inputs = {
  email: string;
  password: string;
};

export function Login() {
  const { handleSubmit, control } = useForm<Inputs>();
  const { signIn, user, isLoading } = useUser();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => signIn(data);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <Layout>
      <S.Section>
        <Heading level={3}>Acessar conta</Heading>
        <S.SectionSubtitle>
          Tenha as suas ofertas favoritas sempre disponíveis!
        </S.SectionSubtitle>
      </S.Section>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => (
            <Textfield {...field} required type="email" placeholder="Email" />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ field }) => (
            <Textfield
              {...field}
              required
              type="password"
              placeholder="Senha"
            />
          )}
          control={control}
          name="password"
        />

        <S.ForgotPassword onClick={() => navigate('/redefinir-senha')}>
          <span>Esqueci minha senha</span>
        </S.ForgotPassword>

        <Button type="submit" size="large" isLoading={isLoading}>
          Acessar
        </Button>

        <Button
          type="button"
          size="large"
          buttonType="secondary"
          onClick={() => navigate('/cadastrar')}
        >
          Criar nova conta
        </Button>
      </S.Form>
    </Layout>
  );
}
