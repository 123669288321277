import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import api from 'services/api';

import * as S from './styles';
export function Confirm() {
  const navigate = useNavigate();

  const handleConfirmUser = useCallback(async (userId: string) => {
    try {
      const { data } = await api.put(`/user/confirm/${userId}`);

      const { token } = data;

      localStorage.setItem('@folhetonamao:token', token);

      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      const userResponse = await api.get('user');

      const user = userResponse.data;

      localStorage.setItem('@folhetonamao:user', JSON.stringify(user));
      navigate('/');
      message.success('Seu cadastro foi confirmado com sucesso!');
    } catch (err) {
      message.error('Ocorreu um erro ao tentar confirmar o usuário!');
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { id } = Object.fromEntries(urlSearchParams.entries());

    if (!id) {
      return navigate('/');
    }

    handleConfirmUser(id);
  }, [document]);

  return (
    <S.Container>
      <span>Carregando...</span>
    </S.Container>
  );
}
