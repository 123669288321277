import { breakpoints } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
`;

export const Content = styled.div`
  max-width: 1120px;
  min-height: 70vh;
  width: 100%;
  margin: 4rem auto;
  padding-bottom: 4rem;

  @media (min-width: ${breakpoints.xxlarge}) {
    max-width: 1266px;
  }

  @media (max-width: ${breakpoints.xlarge}) {
    padding: 3.2rem;

    margin-top: 0;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 1.6rem;
  }
`;

export const HeaderContainer = styled.div`
  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const DrawerContainer = styled.div`
  display: none;

  @media (max-width: ${breakpoints.large}) {
    display: block;
  }
`;
