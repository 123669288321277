import { secondaryColors, breakpoints } from 'common/styles/theme';
import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;

  h3 {
    margin-bottom: 0;

    @media (max-width: ${breakpoints.small}) {
      font-size: 2.4rem;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const SectionSubtitle = styled.span`
  display: block;
  font-size: 2rem;
  color: ${secondaryColors.grey200};

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.3rem;
  }
`;

export const FlyerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20.2rem, 1fr));
  gap: 2.7rem;
  justify-content: space-between;

  margin-top: 3.2rem;

  @media (max-width: ${breakpoints.small}) {
    grid-template-columns: repeat(auto-fill, calc((100% / 2) - 1.2rem));
    margin-top: 2.4rem;

    gap: 1.2rem;
  }
`;
