import { CitiesProvider } from '../Cities';
import { UserProvider } from '../User';
import { AppProviderProps } from './interfaces';

export function AppProvider({ children }: AppProviderProps) {
  return (
    <CitiesProvider>
      <UserProvider>{children}</UserProvider>
    </CitiesProvider>
  );
}

export default AppProvider;
