import { useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, Heading, Textfield } from 'components';
import { Layout } from 'containers';
import { useUser } from 'hooks';

import * as S from './styles';

type Inputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export function Register() {
  const { handleSubmit, control } = useForm<Inputs>();
  const { user, createAccount } = useUser();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => createAccount(data);

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <Layout>
      <S.Section>
        <Heading level={3}>Criar conta</Heading>
        <S.SectionSubtitle>
          Tenha as suas ofertas favoritas sempre disponíveis!
        </S.SectionSubtitle>
      </S.Section>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          render={({ field }) => (
            <Textfield {...field} required type="text" placeholder="Nome" />
          )}
          control={control}
          name="name"
        />
        <Controller
          render={({ field }) => (
            <Textfield {...field} required type="email" placeholder="Email" />
          )}
          control={control}
          name="email"
        />
        <Controller
          render={({ field }) => (
            <Textfield
              {...field}
              required
              type="password"
              placeholder="Senha"
            />
          )}
          control={control}
          name="password"
        />
        <Controller
          render={({ field }) => (
            <Textfield
              {...field}
              required
              type="password"
              placeholder="Confirmação de senha"
            />
          )}
          control={control}
          name="confirmPassword"
        />

        <Button type="submit" size="large">
          Cadastrar
        </Button>

        <Button
          type="button"
          size="large"
          buttonType="secondary"
          onClick={() => navigate('/acessar')}
        >
          Já tenho cadastro
        </Button>
      </S.Form>
    </Layout>
  );
}
