import { useCallback, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import Modal from 'react-modal';

import { Select } from 'antd';
import horizontalLogo from 'common/assets/svgs/logo/horizontal.svg';
import { Button } from 'components';
import { useCities } from 'hooks';

import { SelectAddressProps } from './interfaces';
import * as S from './styles';

export function SelectAddress({ isOpen, close }: SelectAddressProps) {
  const [selectedCity, setSelectedCity] = useState('');

  const { cities, handleSelectCity } = useCities();

  const handleSelectCityAndState = useCallback((value: string) => {
    handleSelectCity(value);

    document.location.reload();
    close();
  }, []);

  return (
    <Modal isOpen={isOpen} style={S.ModalStyles}>
      <S.Container>
        <img src={horizontalLogo} alt="Folheto na mão" />
        <span>Para continuar, selecione sua cidade</span>
        <Select
          defaultValue={selectedCity ? selectedCity : 'Selecione sua cidade...'}
          style={{
            width: '100%',
            marginTop: '2rem',
            marginBottom: '2rem',
            border: '1px solid #eee',
          }}
          suffixIcon={<MdExpandMore />}
          bordered={false}
          onChange={value => {
            setSelectedCity(value);
          }}
        >
          {cities.map(city => (
            <Select.Option
              key={city.city}
              value={`${city.city}, ${city.state}`}
            >
              {city.city}
            </Select.Option>
          ))}
        </Select>

        <S.ContinueWithoutCity
          onClick={() =>
            handleSelectCityAndState(`${cities[0].city}, ${cities[0].state}`)
          }
        >
          Não achou sua cidade? Clique aqui
        </S.ContinueWithoutCity>

        <Button
          size="large"
          onClick={() => handleSelectCityAndState(selectedCity)}
          disabled={selectedCity === ''}
        >
          Continuar
        </Button>
      </S.Container>
    </Modal>
  );
}
