import { Link } from 'react-router-dom';

import horizontalLogo from 'common/assets/svgs/logo/horizontal.svg';

import * as S from './styles';

export function Footer() {
  return (
    <S.Container>
      <S.Content>
        <img src={horizontalLogo} alt="Folheto na mão" />

        <S.Links>
          <ul>
            <li>
              <b>Usuário</b>
              <Link to="/sobre">Sobre</Link>
              <Link to="/como-funciona">Como funciona</Link>
              <Link to="/sustentabilidade">Sustentabilidade</Link>
              <Link to="/politicas-privacidades">Política de Privacidade</Link>
              <Link to="/termos-e-condicoes">Termos e Condições de Uso</Link>
            </li>
          </ul>
          <ul>
            <li>
              <b>Empresa</b>
              {/* <a href="http://folhetonamao.com.br/static/pdf/como-funciona.pdf">
                Saiba mais
              </a>
              <Link to="/planos">Planos</Link> */}
              <Link to="/anunciantes">Anunciantes</Link>
              <a href="mailto:contato@folhetonamao.com.br">
                contato@folhetonamao.com.br
              </a>
              <a href="mailto:controlador@folhetonamao.com.br">
                controlador@folhetonamao.com.br
              </a>
              <a href="mailto:vendas@folhetonamao.com.br">
                vendas@folhetonamao.com.br
              </a>
            </li>
          </ul>

          <ul>
            <li>
              <b>Social</b>
              <a
                href="https://business.facebook.com/folhetonamao.com.br/?ref=page_internal"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
              <a
                href="https://www.instagram.com/folhetonamao/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
          </ul>
        </S.Links>
      </S.Content>
      <S.Copyright>
        <span>Copyright © Folheto na mão</span>
      </S.Copyright>
    </S.Container>
  );
}
