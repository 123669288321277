import { useEffect, useCallback, useState } from 'react';
import {
  MdFavorite,
  MdFavoriteBorder,
  MdOpenInNew,
  MdOutlineEmail,
} from 'react-icons/md';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useParams, useNavigate } from 'react-router-dom';

import { Popover } from 'antd';
import { message } from 'antd';
import axios from 'axios';
import { env } from 'configs';
import { Layout } from 'containers';
import { format } from 'date-fns';
import { useUser } from 'hooks';
import { StoreDetails } from 'pages/Store/interfaces';
import api from 'services/api';

import { FlyerData } from './interfaces';
import * as S from './styles';

export function FlyerDetail() {
  const [flyer, setFlyer] = useState<FlyerData>({} as FlyerData);
  const [shop, setShop] = useState<StoreDetails>({} as StoreDetails);
  const [hasBookmark, setHasBookmark] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [notificationId, setNotificationId] = useState(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [bookmarkId, setBookmarkId] = useState(null);
  const [currentFlyer, setCurrentFlyer] = useState(0);
  const [loading, setLoading] = useState(true);
  const [zoom, setZoom] = useState(false);
  const { id } = useParams();
  const { user } = useUser();
  const navigate = useNavigate();

  const handleGetFlyerDetails = useCallback(async (id: string) => {
    try {
      const { data } = await api.get(`flyer/${id}`);

      setFlyer(data);
      setShop(data.shop);
      setLoading(false);

      if (user) {
        handleValidateBookmark(user.id, data.shop.id);
        handleValidateNotifications(user.id, data.shop.id);
      }
    } catch (err) {
      console.log(err);
      if (axios.isAxiosError(err)) {
        navigate('/');
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, []);

  const handleReceiveEmail = useCallback(async () => {
    if (!user) {
      navigate('/acessar');

      message.info(
        'Para receber notificações você precisa acessar a sua conta',
      );
    } else {
      try {
        await api.post(`user/notification`, {
          user_id: user.id,
          shop_id: shop.id,
        });
        message.success('Notificação ativada com sucesso!');
      } catch (err) {
        message.error('Ocorreu um erro ao tentar ativar as notificações');
      }
    }
  }, [user, shop]);

  const handleRemoveEmail = useCallback(async () => {
    try {
      await api.delete(`user/notification/${notificationId}`);
      message.success('Notificação removida com sucesso!');
      setNotificationId(null);

      document.location.reload();
    } catch (err) {
      message.error('Ocorreu um erro ao tentar ativar as notificações');
    }
  }, [notificationId]);

  const handleRemoveBookmark = useCallback(async () => {
    try {
      await api.delete(`user/bookmark/${bookmarkId}`);
      message.success('Favorito removida com sucesso!');
      setBookmarkId(null);

      document.location.reload();
    } catch (err) {
      message.error('Ocorreu um erro ao tentar ativar as notificações');
    }
  }, [bookmarkId]);

  const handleAddBookmark = useCallback(async () => {
    if (!user) {
      navigate('/acessar');

      message.info(
        'Para adicionar aos favoritos você precisa acessar a sua conta',
      );
    } else {
      try {
        await api.post(`user/bookmark`, {
          user_id: user.id,
          shop_id: shop.id,
        });
        message.success('Favorito cadastrado com sucesso!');
      } catch (err) {
        message.error('Ocorreu um erro ao tentar adicionar aos favoritos');
      }
    }
  }, [user, shop]);

  const handleValidateBookmark = useCallback(
    async (userId: string, shopId: string) => {
      try {
        const { data } = await api.get(
          `/user/${userId}/shop/${shopId}/bookmark`,
        );

        if (data.id) {
          setHasBookmark(true);
          setBookmarkId(data.id);
        }
      } catch (err) {
        // if (axios.isAxiosError(err)) {
        //   if (err.response?.status === 404) {
        //     setHasBookmark(false);
        //   }
        // } else {
        //   message.error('Ocorreu um erro ao tentar verificar favoritos');
        // }
      }
    },
    [],
  );

  const handleValidateNotifications = useCallback(
    async (userId: string, shopId: string) => {
      try {
        const { data } = await api.get(
          `/user/${userId}/shop/${shopId}/notification`,
        );

        if (data.id) {
          setHasNotification(true);
          setNotificationId(data.id);
        }
      } catch (err) {
        // if (axios.isAxiosError(err)) {
        //   if (err.response?.status === 404) {
        //     setHasNotification(false);
        //   }
        // } else {
        //   message.error('Ocorreu um erro ao tentar verificar favoritos');
        // }
      }
    },
    [],
  );

  useEffect(() => {
    if (id) {
      handleGetFlyerDetails(id);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Layout>
      {!loading && (
        <>
          <S.StoreDetails>
            <S.StoreDescription>
              <div>
                <img
                  src={`${env.apiUrl}/images/shops/${flyer.shop.image}`}
                  alt={flyer.shop.name}
                />
                <span>
                  <b>Sobre: </b>
                  {shop.description}
                </span>
                <br />
                <span>
                  <b>Horário de funcionamento: </b>
                  {shop.opening_time} | {shop.closing_time}
                </span>
                <span>
                  <b>Métodos de pagamento: </b>
                  {shop.payment_methods}
                </span>
                <span>
                  <b>Telefone: </b>
                  {shop.phone}
                </span>
                {shop.link && (
                  <span>
                    <b>Site: </b>
                    <a href={shop.link} target="_blank" rel="noreferrer">
                      {shop.link}
                    </a>
                  </span>
                )}
              </div>

              <S.NotificationsButtonContainer>
                {hasNotification ? (
                  <button onClick={() => handleRemoveEmail()}>
                    <MdOutlineEmail />
                    <span>Remover notificação</span>
                  </button>
                ) : (
                  <button onClick={() => handleReceiveEmail()}>
                    <MdOutlineEmail />
                    <span>Receber notificações</span>
                  </button>
                )}

                {hasBookmark ? (
                  <button onClick={() => handleRemoveBookmark()}>
                    <MdFavorite />
                    <span>Remover favorito</span>
                  </button>
                ) : (
                  <button onClick={() => handleAddBookmark()}>
                    <MdFavoriteBorder />
                    <span>Adicionar aos favoritos</span>
                  </button>
                )}
              </S.NotificationsButtonContainer>
            </S.StoreDescription>
            <S.MapContainerDesktop>
              <iframe
                title="map"
                src={shop.map_link}
                style={{ border: 0 }}
                loading="lazy"
              />

              <span>
                {`${shop.address}, ${shop.number}, ${shop.city} - ${shop.state}, ${shop.country}`}
              </span>
            </S.MapContainerDesktop>
          </S.StoreDetails>
          <S.FlyerDetails>
            {flyer.images.length > 1 ? (
              <>
                {flyer.images.map((image, index) => (
                  <S.FlyerContainer
                    key={image.id}
                    zoom={zoom}
                    onClick={() => setZoom(!zoom)}
                    hidden={currentFlyer !== index}
                  >
                    <img
                      src={`${env.apiUrl}/images/flyers/${flyer.id}/${image.file}`}
                      alt=""
                    />
                  </S.FlyerContainer>
                ))}

                <S.PaginationContainer>
                  {flyer.images.map((_, index) => (
                    <S.PaginationButton
                      key={index}
                      onClick={() => setCurrentFlyer(index)}
                      isActive={currentFlyer === index}
                    >
                      {index + 1}
                    </S.PaginationButton>
                  ))}
                </S.PaginationContainer>
              </>
            ) : flyer.images.length > 0 ? (
              <S.FlyerContainer zoom={zoom} onClick={() => setZoom(!zoom)}>
                <img
                  src={`${env.apiUrl}/images/flyers/${flyer.id}/${flyer.images[0].file}`}
                  alt=""
                />
              </S.FlyerContainer>
            ) : (
              <S.FlyerContainer zoom={zoom}>
                <img
                  src="https://via.placeholder.com/202x269.png?text=nenhuma+imagem+encontrada"
                  alt=""
                />
              </S.FlyerContainer>
            )}

            <S.ExpirationContainer>
              <span>
                Data da postagem:{' '}
                <b>{format(new Date(flyer.created_at), 'dd/MM/yyyy')}</b>
              </span>
              <span>
                Válido até:{' '}
                <b>{format(new Date(flyer.expiration_date), 'dd/MM/yyyy')}</b>
              </span>
              <Popover
                content={
                  <S.ShareContentButton
                    href={`whatsapp://send?text=${window.location.href}`}
                    data-action="share/whatsapp/share"
                  >
                    WhatsApp
                  </S.ShareContentButton>
                }
                title="Compartilhe"
                trigger="click"
                visible={showSharePopup}
                onVisibleChange={() => setShowSharePopup(!showSharePopup)}
              >
                <S.ShareButton>
                  <MdOpenInNew />
                  <span>Compartilhar</span>
                </S.ShareButton>
              </Popover>
            </S.ExpirationContainer>
            <S.MapContainerMobile>
              <iframe
                title="map"
                src={shop.map_link}
                style={{ border: 0 }}
                loading="lazy"
              />

              <span>
                {`${shop.address}, ${shop.number}, ${shop.city} - ${shop.state}, ${shop.country}`}
              </span>
            </S.MapContainerMobile>
          </S.FlyerDetails>
        </>
      )}
    </Layout>
  );
}
