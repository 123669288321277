/*
 * @vinniciusgomes
 * This file is for:
 * This file is responsible for validating whether the client will access private routes if logged in or if it will use public routes.
 */
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import {
  Home,
  Promotions,
  Supermarket,
  FlyerDetail,
  Flyers,
  Login,
  Register,
  Store,
  About,
  HowItWorks,
  Sustainability,
  Advertisers,
  Bookmarks,
  Confirm,
  ForgotPassword,
  EmailSend,
  CreateNewPassword,
  ResetPasswordSuccess,
  Plans,
  Unsubscribe,
  PrivacyPolicies,
  TermsAndConditionsOfUse,
} from 'pages';

import { PrivateRoute, PublicRoute } from './Routes';

interface Routes {
  path: string;
  isPrivate: boolean;
  element: JSX.Element;
}

const routes: Routes[] = [
  {
    path: '/',
    isPrivate: false,
    element: <Home />,
  },
  {
    path: '/acessar',
    isPrivate: false,
    element: <Login />,
  },
  {
    path: '/cadastrar',
    isPrivate: false,
    element: <Register />,
  },
  {
    path: '/promocoes',
    isPrivate: false,
    element: <Promotions />,
  },
  {
    path: '/supermercados',
    isPrivate: false,
    element: <Supermarket />,
  },
  {
    path: '/ofertas',
    isPrivate: false,
    element: <Flyers />,
  },
  {
    path: '/panfleto/:id',
    isPrivate: false,
    element: <FlyerDetail />,
  },
  {
    path: '/loja/:id',
    isPrivate: false,
    element: <Store />,
  },
  {
    path: '/sobre',
    isPrivate: false,
    element: <About />,
  },
  {
    path: '/como-funciona',
    isPrivate: false,
    element: <HowItWorks />,
  },
  {
    path: '/sustentabilidade',
    isPrivate: false,
    element: <Sustainability />,
  },
  {
    path: '/anunciantes',
    isPrivate: false,
    element: <Advertisers />,
  },
  {
    path: '/favoritos',
    isPrivate: false,
    element: <Bookmarks />,
  },
  {
    path: '/confirmar',
    isPrivate: false,
    element: <Confirm />,
  },
  {
    path: '/redefinir-senha',
    isPrivate: false,
    element: <ForgotPassword />,
  },
  {
    path: '/redefinir-senha/enviado',
    isPrivate: false,
    element: <EmailSend />,
  },
  {
    path: '/nova-senha',
    isPrivate: false,
    element: <CreateNewPassword />,
  },
  {
    path: '/nova-senha/sucesso',
    isPrivate: false,
    element: <ResetPasswordSuccess />,
  },
  {
    path: '/planos',
    isPrivate: false,
    element: <Plans />,
  },
  {
    path: '/unsubscribe',
    isPrivate: false,
    element: <Unsubscribe />,
  },
  {
    path: '/politicas-privacidades',
    isPrivate: false,
    element: <PrivacyPolicies />,
  },
  {
    path: '/termos-e-condicoes',
    isPrivate: false,
    element: <TermsAndConditionsOfUse />,
  },
];

export function Routes() {
  return (
    <ReactRouterDomRoutes>
      {routes.map(({ path, isPrivate, element }) =>
        isPrivate ? (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={<PublicRoute>{element}</PublicRoute>}
          />
        ),
      )}
    </ReactRouterDomRoutes>
  );
}
