import { useEffect } from 'react';

import { Heading } from 'components';
import { Layout } from 'containers';

import * as S from './styles';

export function Sustainability() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>Por um mundo mais sustentável</Heading>

        <p>
          A sustentabilidade vem se tornando um tema muito relevante, mostrando
          que políticas ambientais são cada vez mais necessárias. O{' '}
          <b>FOLHETO NA MÃO</b> colabora para o bem estar de nosso meio
          ambiente, já que por ser digital diminui o uso de papel e
          consequentemente o impacto nas emissões de CO2, uma vez que não é
          necessário o transporte de papel para serem confeccionados os
          tradicionais folhetos impressos. Você pode contribuir para diminuir os
          impactos ambientais adotando o hábito de utilizar os folhetos
          digitais. Essa é a sua forma de contribuir com o meio ambiente .
        </p>
      </S.Container>
    </Layout>
  );
}
