import { MdOutlineDeleteForever } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';

import { env } from 'configs';

import { StoreProps } from './interfaces';
import * as S from './styles';

export function Store({
  data,
  handleBookmark,
  showBookmarkButton = false,
}: StoreProps) {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(`/loja/${data.id}`)}>
      {showBookmarkButton && handleBookmark && (
        <S.BookmarkButton onClick={() => handleBookmark(data.id)}>
          <MdOutlineDeleteForever />
        </S.BookmarkButton>
      )}
      <S.Image
        src={`${env.apiUrl}/images/shops/${data.image}`}
        alt={data.name}
      />
      <S.StoreName>{data.name}</S.StoreName>
      <Link to={`/loja/${data.id}`}>Abrir loja</Link>
    </S.Container>
  );
}
