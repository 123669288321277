import { breakpoints, font, secondaryColors } from 'common/styles/theme';
import styled, { css } from 'styled-components';

interface FlyerContainerProps {
  zoom: boolean;
}

interface PaginationButton {
  isActive: boolean;
}

export const StoreDetails = styled.div`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 3fr 1fr;
  gap: 6rem;

  @media (max-width: ${breakpoints.large}) {
    grid-template-columns: 1fr;
  }
`;

export const StoreDescription = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 15rem;
    margin-bottom: 3.2rem;
  }

  span {
    display: block;
    font-size: 1.5rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
  }

  b {
    color: #222;
  }
`;

export const NotificationsButtonContainer = styled.div`
  margin-top: 3.2rem;

  display: flex;
  align-items: center;
  gap: 3rem;

  button {
    display: flex;
    align-items: center;
    background: transparent;
    border: 0;

    span {
      color: ${secondaryColors.orange100};
    }

    svg {
      color: ${secondaryColors.orange100};
      margin-right: 1rem;
    }
  }

  @media (max-width: ${breakpoints.small}) {
    span {
      font-size: 1.4rem;
    }
  }
`;

export const MapContainerDesktop = styled.div`
  width: 44.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    width: 100%;
    max-width: 19.8rem;
    background-color: ${secondaryColors.orange100};

    margin-top: 3.2rem;
    height: 4.7rem;
    border-radius: 0.5rem;

    span {
      font-size: 1.3rem;
    }

    :hover {
      background-color: ${secondaryColors.orange200};
    }
  }

  iframe {
    width: 44.3rem;
    height: 26.5rem;
    border-radius: 0.5rem;
    border: 0.1rem solid ${secondaryColors.grey300};

    @media (max-width: ${breakpoints.small}) {
      width: 100%;
      min-height: 26.5rem;
    }
  }

  > span {
    width: 44.3rem;
    display: block;
    font-size: 1.3rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const MapContainerMobile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  overflow: hidden;

  button {
    width: 100%;
    max-width: 19.8rem;
    background-color: ${secondaryColors.orange100};

    margin-top: 3.2rem;
    height: 4.7rem;
    border-radius: 0.5rem;

    span {
      font-size: 1.3rem;
    }

    :hover {
      background-color: ${secondaryColors.orange200};
    }
  }

  iframe {
    border-radius: 0.5rem;
    border: 0.1rem solid ${secondaryColors.grey300};

    width: 100%;
    min-height: 26.5rem;
  }

  > span {
    width: 44.3rem;
    display: block;
    font-size: 1.3rem;
    color: ${secondaryColors.grey200};
    max-width: 61.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (min-width: ${breakpoints.large}) {
    display: none;
  }
`;

export const FlyerContainer = styled.div<FlyerContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ zoom }) => css`
    margin-top: 6.4rem;
    cursor: ${zoom ? 'zoom-out' : 'zoom-in'};

    img {
      max-width: ${zoom ? '100%' : '40rem'};
      width: 100%;
      border-radius: 0.5rem;
      border: 0.1rem solid ${secondaryColors.grey300};
    }
  `}
`;

export const FlyerDetails = styled.div`
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
  }

  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
  }
`;

export const ExpirationContainer = styled.div`
  margin-top: 3.2rem;

  span {
    display: block;

    b {
      color: ${secondaryColors.orange100};
    }
  }

  span + span {
    margin-top: 1.6rem;
  }
`;

export const ShareButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;

  margin-top: 2.8rem;

  span {
    color: ${secondaryColors.orange100};
    font-weight: ${font.medium};
  }

  svg {
    color: ${secondaryColors.orange100};
    margin-right: 1rem;
    font-size: 2rem;
  }
`;

export const ShareContentButton = styled.a`
  color: ${secondaryColors.orange100};
  cursor: pointer;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 2.4rem;

  button + button {
    margin-left: 1rem;
  }
`;

export const PaginationButton = styled.button<PaginationButton>`
  width: 3.4rem;
  height: 3.4rem;

  background-color: ${({ isActive }) =>
    isActive ? secondaryColors.orange100 : 'transparent'};
  border: 0.1rem solid ${secondaryColors.orange100};
  color: ${({ isActive }) => (isActive ? '#fff' : secondaryColors.orange100)};
  border-radius: 0.4rem;

  transition: 0.3s;

  :hover {
    background-color: ${secondaryColors.orange100};
    color: #fff;
  }
`;
