import { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css';

import GlobalStyles from 'common/styles/global';
import { SelectAddress } from 'components';
import { AppProvider } from 'hooks';
import { Routes } from 'routes';

function Bootstrap() {
  const [showSelectAddressModal, setSelectAddressModal] = useState(false);

  const city = localStorage.getItem('@folhetonamao:city');

  useEffect(() => {
    if (!city && !window.location.pathname.includes('/loja')) {
      setSelectAddressModal(true);
    }

    if (window.location.pathname === '/anunciantes') {
      return setSelectAddressModal(false);
    }
  }, [city, window.location.pathname]);

  return (
    <>
      <AppProvider>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>

        <SelectAddress
          isOpen={showSelectAddressModal}
          close={() => setSelectAddressModal(false)}
        />

        <GlobalStyles />
      </AppProvider>
    </>
  );
}

export default Bootstrap;
