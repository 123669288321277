import { font, secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

const width =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

export const ModalStyles = {
  content: {
    background: '#fff',
    width: width > 650 ? '650px' : 'calc(100% - 32px)',
    inset: '0px',
    height: '350px',
    margin: 'auto auto',
    border: 0,
    borderRadius: '10px',
    boxShadow: '0px 3px 12px #00000029',
    zIndex: 999,
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.30)',
    backdropFilter: 'blur(2px)',
    zIndex: 999,
  },
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  img {
    max-width: 16rem;
  }

  > span {
    display: block;
    margin-top: 2rem;
    font-size: 2rem;

    font-weight: ${font.bold};
  }

  > button {
    border-radius: 0.5rem;
    height: 4rem;
    background: ${secondaryColors.orange100};

    :hover {
      background: ${secondaryColors.orange200};
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContinueWithoutCity = styled.button`
  background: transparent !important;
  border: none;
  font-size: 1.5rem;
  height: auto !important;
  outline: none;
  margin: 0 0 2.4rem !important;
  transition: 0.3s;

  :hover {
    color: ${secondaryColors.orange100};
  }
`;
