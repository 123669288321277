import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import api from 'services/api';

export function Unsubscribe() {
  const navigate = useNavigate();

  const handleRemoveEmail = useCallback(async (notificationId: string) => {
    try {
      await api.delete(`newsletter/${notificationId}`);
      message.success('Notificação removida com sucesso!');

      navigate('/');
    } catch (err) {
      message.error('Ocorreu um erro ao tentar ativar as notificações');
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');

    if (id) {
      handleRemoveEmail(id);
    } else {
      navigate('/');
    }
  }, [window]);

  return <div>Carregando...</div>;
}
