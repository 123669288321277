import { useCallback, useEffect, useState } from 'react';
import { MdArrowForward } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import { Adsense } from '@ctrl/react-adsense';
import { message, Skeleton } from 'antd';
import axios from 'axios';
import { Flyer, Store, Heading } from 'components';
import { Layout } from 'containers';
import { useCities } from 'hooks';
import { FlyerModel, StoreModel } from 'models';
import api from 'services/api';

import * as S from './styles';

export function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [promotionFlyers, setPromotionFlyers] = useState<FlyerModel[]>([]);
  const [stores, setStores] = useState<StoreModel[]>([]);
  const navigate = useNavigate();
  const { selectedCity, selectedState } = useCities();

  const handleGetPromotionFlyers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `flyer?state=${selectedState}&city=${selectedCity}&limit=10`,
      );

      setPromotionFlyers(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectedCity, selectedState]);

  const handleGetStores = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `shop?state=${selectedState}&city=${selectedCity}&limit=5`,
      );

      setStores(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    } finally {
      setIsLoading(false);
    }
  }, [selectedCity, selectedState]);

  useEffect(() => {
    if (selectedCity && selectedState) {
      handleGetPromotionFlyers();
      handleGetStores();
    }
  }, [selectedCity, selectedState]);

  return (
    <Layout>
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Folhetos</Heading>
            <S.SectionSubtitle>
              As últimas folhetos na sua região
            </S.SectionSubtitle>
          </div>
          <S.ViewAllContainer onClick={() => navigate('/ofertas')}>
            <span>Ver tudo</span>
            <MdArrowForward />
          </S.ViewAllContainer>
        </S.SectionHeader>
        {/* <Adsense
          client="ca-pub-9348939600671599"
          slot="1234567890"
          style={{ width: '100%', height: 100 }}
          format=""
        /> */}
        <S.FlyerContainer>
          {promotionFlyers.map(flyer => (
            <Flyer key={flyer.id} data={flyer} />
          ))}

          {isLoading && <Skeleton active />}
        </S.FlyerContainer>
      </S.Section>
      <S.Divider />
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Supermercados</Heading>
            <S.SectionSubtitle>
              Os melhores supermercados da sua região
            </S.SectionSubtitle>
          </div>
          <S.ViewAllContainer onClick={() => navigate('/supermercados')}>
            <span>Ver tudo</span>
            <MdArrowForward />
          </S.ViewAllContainer>
        </S.SectionHeader>
        <S.FlyerContainer>
          {stores.map(store => (
            <Store key={store.id} data={store} />
          ))}

          {isLoading && <Skeleton active />}
        </S.FlyerContainer>
      </S.Section>
    </Layout>
  );
}
