import { useCallback, useEffect, useState } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { Flyer, Heading } from 'components';
import { Layout } from 'containers';
import { useCities } from 'hooks';
import { FlyerModel } from 'models';
import api from 'services/api';

import * as S from './styles';

export function Promotions() {
  const { selectedCity, selectedState } = useCities();
  const [promotionFlyers, setPromotionFlyers] = useState<FlyerModel[]>([]);

  const handleGetPromotionFlyers = useCallback(async () => {
    try {
      const { data } = await api.get(
        `flyer/sales?state=${selectedState}&city=${selectedCity}`,
      );

      setPromotionFlyers(data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, [selectedState, selectedCity]);

  useEffect(() => {
    handleGetPromotionFlyers();
  }, [selectedState, selectedCity]);

  return (
    <Layout>
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Promoções</Heading>
            <S.SectionSubtitle>
              As últimas promoções na sua região
            </S.SectionSubtitle>
          </div>
        </S.SectionHeader>
        <S.FlyerContainer>
          {promotionFlyers.map(flyer => (
            <Flyer key={flyer.id} data={flyer} />
          ))}
        </S.FlyerContainer>
      </S.Section>
    </Layout>
  );
}
