import { useEffect } from 'react';

import { Heading } from 'components';
import { Layout } from 'containers';

import * as S from './styles';

export function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>O que é o Folheto na mão?</Heading>

        <p>
          <b>FOLHETO NA MÃO</b> é um serviço que te auxiliará a otimizar e
          planejar as suas compras. Através do nosso site você terá acesso a
          folhetos promocionais e ofertas dos seus supermercados favoritos mais
          próximos à você. Com um só click você terá todas as informações
          necessárias para escolher onde realizar as suas compras, observando
          qual o melhor preço, qualidade e se beneficiar das seguintes
          vantagens:
          <br />
          <br />
          ● Acesso a todas as ofertas dos pontos de vendas mais próximos de
          você;
          <br />
          <br />
          ● Pesquisa voltada diretamente ao supermercado de sua preferência;
          <br />
          <br />
          ● Notificações para você estar sempre atualizado sobre as últimas
          ofertas.
          <br />
          <br />● Localização exata do supermercado escolhido por você. Em caso
          de necessidade de assistência, você pode nos contatar através das
          nossas redes sociais ou do nosso e-mail.
        </p>

        <p>
          <b>Sede legal:</b> Rua Vitório Zambom n. 33, Jardim Santa Cruz Araras
          SP - Cep: 13601-216 <br />
          <br />
          <b>CNPJ:</b> 45.227.643/0001-00 <br />
          <br />
          <b>Contato:</b> contato@folhetonamao.com.br
        </p>
      </S.Container>
    </Layout>
  );
}
