import { Link, useNavigate } from 'react-router-dom';

import { env } from 'configs';

import { FlyerProps } from './interfaces';
import * as S from './styles';

export function Flyer({ data }: FlyerProps) {
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(`/panfleto/${data.id}`)}>
      {data.images.length > 0 ? (
        <S.ImageContainer>
          <S.OpenButton>Folhear panfleto</S.OpenButton>
          <S.Image
            src={`${env.apiUrl}/images/flyers/${data.id}/${data.images[0]?.file}`}
            alt={data.shop.name}
          />
        </S.ImageContainer>
      ) : (
        <S.ImageContainer>
          <S.OpenButton>Folhear panfleto</S.OpenButton>
          <S.Image
            src="https://via.placeholder.com/202x269.png?text=nenhuma+imagem+encontrada"
            alt={data.shop.name}
          />
        </S.ImageContainer>
      )}

      <S.StoreName>{data.shop.name}</S.StoreName>
      <Link to={`/panfleto/${data.id}`}>Abrir panfleto</Link>
    </S.Container>
  );
}
