import {
  breakpoints,
  font,
  primaryColors,
  secondaryColors,
} from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
`;

export const Content = styled.div`
  background-color: ${primaryColors.white};
  display: flex;
  align-items: flex-start;
  border-top: 1px solid ${secondaryColors.grey300};

  padding: 7rem 12rem;

  img {
    max-width: 15rem;
    margin-right: 15rem;

    @media (max-width: ${breakpoints.xlarge}) {
      margin-right: 8rem;
    }

    @media (max-width: ${breakpoints.large}) {
      margin-bottom: 3rem;
    }
  }

  @media (max-width: ${breakpoints.large}) {
    flex-direction: column;
    padding: 1.6rem;
  }
`;

export const Links = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 18rem;
  /* width: 15rem; */

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;

    b {
      display: block;
      margin-bottom: 2.2rem;
    }

    a {
      display: block;
      color: ${secondaryColors.grey200};
      font-size: 1.4rem;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;

      &:hover {
        color: ${secondaryColors.orange100};
      }
    }

    a + a {
      margin-top: 1rem;
    }
  }

  @media (max-width: ${breakpoints.xlarge}) {
    gap: 8rem;
  }

  @media (max-width: ${breakpoints.large}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, 50%);
    justify-content: space-around;
    gap: 0rem;
  }

  @media (max-width: ${breakpoints.medium}) {
    grid-template-columns: repeat(auto-fill, 100%);
  }
`;

export const Copyright = styled.div`
  height: 5.2rem;
  background-color: ${primaryColors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${secondaryColors.grey300};

  span {
    font-size: 1.4rem;
    font-weight: ${font.medium};
    color: ${primaryColors.black};
  }
`;
