import { useEffect } from 'react';

import { Heading } from 'components';
import { Layout } from 'containers';

import * as S from './styles';

export function HowItWorks() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>Como funciona o Folheto na mão?</Heading>

        <p>
          Bem-vindos ao <b>FOLHETO NA MÃO</b>, um serviço on-line que te dá
          acesso a todos os folhetos promocionais e às melhores ofertas
          disponíveis em sua região. Faça o seu cadastro e insira a sua
          localidade para ter, em tempo real, todas as melhores ofertas nas suas
          proximidades. Você encontrou uma super promoção e quer saber onde este
          supermercado está localizado? Use nosso ícone de pesquisa e visualize
          o folheto que te interessou e onde este supermercado se encontra, te
          daremos a localização exata através do Google Maps ou Waze para que
          você vá de encontro às suas ofertas.
        </p>
      </S.Container>
    </Layout>
  );
}
