import { font, secondaryColors, breakpoints } from 'common/styles/theme';
import styled from 'styled-components';

export const FlyerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20.2rem, 1fr));
  gap: 2.7rem;
  justify-content: space-between;

  margin-top: 3.2rem;

  @media (max-width: ${breakpoints.small}) {
    grid-template-columns: repeat(auto-fill, calc((100% / 2) - 1.2rem));
    margin-top: 2.4rem;

    gap: 1.2rem;
  }
`;

export const Section = styled.div`
  width: 100%;

  h3 {
    margin-bottom: 0;

    @media (max-width: ${breakpoints.small}) {
      font-size: 2.4rem;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    align-items: flex-start;

    h3 {
      font-size: 3rem;
    }
  }
`;

export const SectionSubtitle = styled.span`
  display: block;
  font-size: 2rem;
  color: ${secondaryColors.grey200};

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.4rem;
  }
`;

export const ViewAllContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 1.6rem;
    display: block;
    color: ${secondaryColors.grey200};
    font-weight: ${font.medium};

    margin-right: 0.6rem;

    @media (max-width: ${breakpoints.small}) {
      font-size: 1.3rem;
    }
  }

  svg {
    color: ${secondaryColors.grey200};
  }

  @media (max-width: ${breakpoints.small}) {
    margin-top: 1rem;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${secondaryColors.grey100};

  margin: 6.2rem 0;

  @media (max-width: ${breakpoints.small}) {
    margin: 2.4rem 0;
  }
`;
