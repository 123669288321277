import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import axios from 'axios';
import { Heading, Store } from 'components';
import { Layout } from 'containers';
import { useCities, useUser } from 'hooks';
import api from 'services/api';

import { Bookmark } from './interfaces';
import * as S from './styles';

export function Bookmarks() {
  const { user } = useUser();
  const { selectedCity, selectedState } = useCities();
  const navigate = useNavigate();
  const [bookmarks, setBookmarks] = useState<Bookmark[]>([]);

  const handleGetBookmarks = useCallback(async () => {
    if (!user) {
      navigate('/acessar');
    } else {
      try {
        const { data } = await api.get(`user/bookmark`);

        setBookmarks(data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          message.error(err.response?.data);
        } else {
          message.error('Ocorreu um erro!');
        }
      }
    }
  }, [selectedCity, selectedState, user]);

  const handleDeleteBookmark = useCallback(async (id: string) => {
    try {
      await api.delete(`/user/bookmark/${id}`);
      message.success('Removido com sucesso!');
      handleGetBookmarks();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        message.error(err.response?.data);
      } else {
        message.error('Ocorreu um erro!');
      }
    }
  }, []);

  useEffect(() => {
    handleGetBookmarks();
  }, [selectedCity, selectedState]);

  return (
    <Layout>
      <S.Section>
        <S.SectionHeader>
          <div>
            <Heading level={3}>Favoritos</Heading>
            <S.SectionSubtitle>
              Sua seleção de lojas favoritas
            </S.SectionSubtitle>
          </div>
        </S.SectionHeader>
        <S.FlyerContainer>
          {bookmarks.map(bookmark => (
            <Store
              key={bookmark.shop.id}
              data={bookmark.shop}
              showBookmarkButton
              handleBookmark={() => handleDeleteBookmark(bookmark.id)}
            />
          ))}
        </S.FlyerContainer>
      </S.Section>
    </Layout>
  );
}
