import { font, primaryColors, secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

interface NavItemProps {
  selected?: boolean;
}

export const Container = styled.div``;

export const Content = styled.div`
  padding: 1.2rem 6.2rem;
  border-bottom: 0.1rem solid ${secondaryColors.grey100};

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    max-width: 16rem;
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 2rem 6.2rem;
  background-color: #ff4c00;
  border-bottom: 0.1rem solid ${secondaryColors.grey100};
`;

export const Nav = styled.nav`
  display: flex;
  gap: 2.4rem;
`;

export const NavItem = styled.div<NavItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${primaryColors.white};
  transition: 0.3s;
  cursor: pointer;

  svg {
    margin-right: 1rem;
    font-size: 2rem;
  }

  span {
    font-size: 1.4rem;
    font-weight: ${font.medium};
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  > svg {
    color: ${primaryColors.white};
    font-size: 2rem;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.6rem;
  color: ${primaryColors.white};

  svg {
    margin-right: 1rem;
    font-size: 2rem;
  }

  span {
    font-size: 1.4rem;
    font-weight: ${font.medium};
  }

  input {
    max-width: 10rem;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UserName = styled.span`
  display: block;
  margin-left: 1rem;
  margin-right: 1rem;

  font-size: 1.4rem;
`;
