import { font, primaryColors, secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  border-bottom: 0.1rem solid ${secondaryColors.grey100};

  background: ${primaryColors.white};

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 13rem;
    margin-left: 1.2rem;
  }

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    background: ${secondaryColors.orange100};

    padding: 2rem 0;

    svg {
      color: #fff;
    }
  }
`;

export const Content = styled.div`
  background-color: #fff;
  height: 100%;
  color: #ff4c00;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Nav = styled.ul`
  list-style: none;
  padding: 2rem;
  padding-bottom: 4rem;

  li + li {
    margin-top: 1.6rem;
  }
`;

export const NavItem = styled.li`
  display: flex;
  color: ${primaryColors.black};
  transition: 0.3s;
  cursor: pointer;
  color: #1c1c1c;

  :hover {
    color: ${primaryColors.black};
  }

  svg {
    margin-right: 1rem;
    font-size: 2rem;
    color: #1c1c1c;
  }

  span {
    font-size: 1.4rem;
    font-weight: ${font.medium};
    color: #1c1c1c;
  }
`;

export const UserContainer = styled.div`
  > div {
    display: flex;
    align-items: center;
  }

  padding: 2rem;
`;

export const UserName = styled.span`
  display: block;
  margin-left: 1rem;
  margin-right: 1rem;

  font-size: 1.4rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #ff4c00;
  padding: 1rem 0 2rem;

  h5 {
    padding-left: 1rem;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
  }

  button {
    width: 100%;
    padding: 0;
    padding: 2rem;
    color: #fff;

    justify-content: start;

    span {
      font-size: 1.4rem;
    }
  }

  hr {
    width: 100%;
  }
`;

export const Options = styled.div`
  display: flex;
  align-items: center;

  > svg {
    color: ${secondaryColors.grey200};
    font-size: 2rem;
  }
`;
