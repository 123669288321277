import { secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  border: 0.1rem solid #eee;
  border-radius: 0.4rem;

  overflow: hidden;
`;

export const HeaderLine = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${secondaryColors.orange100};
`;

export const Content = styled.div`
  padding: 3.2rem;
  text-align: center;
`;

export const PlanName = styled.span`
  font-weight: 400;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
`;

export const PlanPrice = styled.h2`
  font-size: 3.8rem;
  font-weight: 500;
  margin-top: 3.2rem;
  margin-bottom: 5.2rem;
`;

export const PlanDescription = styled.div`
  margin-bottom: 5.2rem;
`;

export const PlanItensList = styled.ul`
  text-align: left;
  list-style: none;

  .disabled {
    opacity: 0.3;
  }

  li {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    svg {
      margin-right: 2rem;
    }
  }
`;
