import { useEffect } from 'react';

import { Heading } from 'components';
import { Layout } from 'containers';

import * as S from './styles';

export function TermsAndConditionsOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>
          Termos e Condições de Uso do site www.folhetonamao.com.br
        </Heading>
        <p>
          Bem-vindo(a) ao nosso website. A empresa Daniela Vancini 28709996826,
          com nome fantasia “Folheto Folheto na Mão”, pessoa jurídica de direito
          privado, inscrita no CNPJ nº 45.227.643/0001-00, com sede na Rua
          Vitório Zambom, nº. 33, Jardim Santa Cruz, CEP 13.601-216, na cidade
          de Araras, Estado de São Paulo, possui este site com o objetivo de
          ajudar os utilizadores a encontrar os produtos que procuram, a
          comparar as diferentes lojas e preços, e, por conseguinte, fazer o
          melhor negócio possível. O compromisso da empresa é respeitar a
          privacidade e garantir o sigilo de todas as informações que o titular
          de dados fornece, através do site www.folhetonamao.com.br. Por isso,
          com o objetivo de fornecer informações claras e precisas aos titulares
          acerca do tratamento de dados pessoais, a empresa apresenta os Termos
          e Uso do Site e a Política de Privacidade. As determinações abaixo
          representam um contrato entre o usuário/titular de dados e a empresa
          e, ao cadastrar-se ou utilizar-se dos serviços prestados, o titular
          concorda, sem restrições, em aceitar tacitamente os Termos e Condições
          de Uso, uma vez que estas disposições norteiam a política de
          privacidade e regras de utilização do site da empresa.
        </p>
        <p>
          <b> 1. Do Objeto.</b>
        </p>
        Este site é uma plataforma digital de conteúdo e divulgação de folhetos
        promocionais e tem por finalidade auxiliar o consumidor, através da
        publicidade de produtos e preços, em sua jornada de compra online. O
        site Folheto na Mão é um serviço on-line que disponibiliza folhetos
        promocionais de supermercados parceiros e os reúne em um só lugar,
        facilitando assim, as pesquisas por melhores preços e ofertas pelos
        usuários. Com um simples cadastro o usuário inserirá sua localidade e
        assim terá acesso às promoções disponíveis em sua região ou na região
        que desejar. A empresa, através do site Folheto na Mão, pretende que
        todas as informações presentes no site sejam atualizadas e verdadeiras e
        procurará corrigir todos os erros que lhe sejam comunicados. Os
        conteúdos presentes no site não constituem conselho, sugestão ou
        obrigação, nem estabelecem qualquer relação contratual de
        responsabilização. A empresa, através do site Folheto na Mão, não
        responde por quaisquer perdas ou danos, diretos ou indiretos, sofridos
        por qualquer utilizador, relativamente à informação contida no mesmo.
        Também, a empresa, através do site Folheto na Mão, não é responsável
        pela exatidão, qualidade, segurança, legalidade ou licitude, incluindo o
        cumprimento das regras respeitantes a direitos de autor e direitos
        conexos, relativamente aos conteúdos, produtos ou serviços contidos
        neste site que tenham sido fornecidos pelos anunciantes ou parceiros.
        Destaca-se que a empresa não se responsabiliza pelo mau uso destas
        informações, e que fará o possível para manter todos os dados
        atualizados.
        <p>
          <b>2. Da Aceitação. </b>
        </p>
        O presente documento “Termos e Condições de Uso” aplicáveis ao uso do
        site www.folhetonamao.com.br, especifica que o usuário antes de acessar
        o site leia bem e revise os termos presentes, pois estabelece obrigações
        contratadas de livre e espontânea vontade por tempo indeterminado entre
        todos os usuários ou visitantes do site. Ao utilizar ou acessar a
        plataforma, o usuário aceita e se compromete com as regras estabelecidas
        de maneira integral, sob o risco de ser penalizado criminalmente. A
        aceitação do presente instrumento é imprescindível para o acesso à
        página. Caso não concorde com os termos e condições de uso que constam
        no decorrer desse documento, por favor não acesse ou use o site. O Site
        se reserva ao direito de atualização dos termos de uso periodicamente,
        sem qualquer aviso, podendo assim, esses sofrer atualizações ou
        modificações.
        <p>
          <b>3. Do acesso dos usuários. </b>
        </p>
        Em alguns dias ou horários o acesso à página poderá vir a ser
        interrompido, limitado ou suspenso, devido a modificações, atualizações
        ou ações semelhantes que sejam necessárias para melhorar o desempenho e
        acesso aos usuários e ao proprietário. O site e qualquer serviço
        oferecido pela página só poderão ser disponibilizados a você caso
        concorde integralmente com a legislação aplicável e com este termo de
        uso. Menores de 18 anos ou aqueles que não possuírem plena capacidade
        civil devem obter o prévio consentimento expresso dos responsáveis para
        ter acesso à página, sendo responsabilidade exclusiva dos mesmos, o
        acesso ao conteúdo da página por menores de 18 anos ou por aqueles que
        não tenham capacidade civil. Se você for menor de 18 anos, não acesse o
        site sem autorização do seu responsável. Preste bastante atenção, ao
        utilizar/acessar esse site você declara que tem capacidade jurídica
        plena, ou seja, declara ser maior de 18 anos e ser capaz civilmente.
        <p>
          <b>4. Do Cadastro. </b>
        </p>
        Para o cadastro de usuário, solicitamos informações como: nome, e-mail,
        senha e CEP. Utilizamos o e-mail do usuário apenas quando autorizado
        pelo mesmo, para comunicar promoções de produtos e serviços. Assim, para
        o usuário ter acesso à página/funcionalidades/serviços pode ser
        necessário a realização de um cadastro prévio. Ao se cadastrar o usuário
        deverá informar corretamente os dados completos, sendo sua a exclusiva
        responsabilidade de atualizá-los, bem como o seu comprometimento que os
        dados apresentados tem veracidade. O usuário do site ainda se compromete
        a não informar a qualquer pessoa/terceiros os seus dados cadastrais que
        contém na plataforma e se responsabiliza pelo uso e pelo que seja feito.
        Em alguns casos, após a confirmação cadastral, o usuário terá um login
        (usuário e senha) e assim poderá acessar a página. O acesso é
        individual. Qualquer atividade utilizada com o login é de total
        responsabilidade do usuário. Em caso de uso indevido da senha ou perda,
        o usuário deverá informar rapidamente a plataforma. A conta é
        intransferível. Portanto, o site Folheto na Mão permite ao titular dos
        dados pessoais a consulta facilitada, de forma gratuita, através de seu
        usuário e senha cadastrados no mesmo. Todos os dados cadastrados no site
        Folheto na Mão são utilizados apenas para melhorar a experiência de
        navegação e manter o usuário atualizado sobre as promoções e vantagens
        oferecidas pelas empresas parceiras.
        <p>
          <b>5. Do Envio de E-mails.</b>
        </p>
        O e-mail da empresa com promoções tem como remetente:
        no-reply@folhetonamao.com.br. Nosso e-mail para atendimento e dúvidas é
        contato@folhetonamao.com.br. Os links dos e-mails levam diretamente para
        o site www.folhetonamao.com.br.
        <p>
          <b>6. Licença. </b>
        </p>
        A empresa Folheto na Mão concede uma licença limitada, intransferível,
        revogável, não exclusiva e não passível da qual estão autorizando a
        visualizar os materiais exibidos no site, exclusivamente para uso
        pessoal sem fins lucrativos. Todos os materiais encontrados no site,
        incluindo, mas não se limitando, aos sons, vídeos, áudios, logotipos,
        nomes comerciais, disposições, ilustrações, fotografias, obras de artes,
        código de HTML, design da página e software são de propriedade da
        empresa e dos contratantes/fornecedores de conteúdo, tendo esses
        reservados o direito em relação à propriedade intelectual e proibido sua
        cópia sem prévia autorização expressa. Os materiais contidos na
        plataforma são resguardados pela Lei 9.610/18- Lei dos Direitos Autorais
        e outras regras, regulamentos pertinentes e legislações nacionais ou
        internacionais de propriedade intelectual aplicáveis. Esses termos de
        uso não cedem ou transferem ao usuário do site qualquer direito à
        utilização da propriedade intelectual, a não ser a concedida para uso
        pessoal ou a não ser a concedida com a licença limitada que foi
        adquirida. O usuário não poderá usar qualquer componente da página sem o
        consentimento prévio e expresso do proprietário e respectivos titulares.
        Os usos indevidos da página ou dos conteúdos contidos no mesmo violam os
        direitos de propriedade intelectual da empresa e de seus
        contratantes/fornecedores, e são puníveis com os termos da legislação
        aplicável.
        <p>
          <b>7. Proibições Gerais. </b>
        </p>
        Em relação ao seu USO, o usuário do site concorda em NÃO: 1 – Violar
        quaisquer direitos relacionados à privacidade ou propriedade
        intelectual, incluindo, sem qualquer limitação, patentes, marcas,
        direitos autorais, segredos comerciais de terceiros, incluindo a (nome
        físico ou jurídico) e seus sócios/afiliados. 2 – Violar qualquer uma das
        regras, legislações ou regulamentos aplicáveis, legislações referentes a
        exportação, leis contra a discriminação, leis que violem os direitos
        autorais. 3 – Publicar, armazenar, carregar, copiar qualquer material
        que: a) É considerado ilegal, difamatório, ofensivo, enganoso, danoso,
        ameaçador, fraudulento, constrangedor ou obsceno; b) Interfira de
        qualquer maneira nas operações e utilização normal do site, como
        publicar ou transmitir arquivos de vírus, spams, cartas de correntes,
        franquias ou algo semelhante; c) Violar qualquer obrigação contratual;
        d) Violar a confidencialidade; e) Constranger ou perseguir terceiro com
        algum tipo de mensagem ou algo semelhante; f) Violar ou tentar violar
        medidas de segurança do Site; g) Acessar ou utilizar conta de terceiro
        no site; h) Utilizar robôs ou sistemas de varredura para monitorar ou
        acessar arquivos do site; i) Modificar, copiar, reproduzir, distribuir,
        apagar, armazenar, transmitir, vender, revender, publicar e quaisquer
        termos afins, dos materiais e conteúdos presentes no site/blog, exceto
        com autorização ou que sejam de sua propriedade; j) Fingir ser outra
        pessoa/terceiro; k) Publicar qualquer informação considerada falta,
        incompleta ou imprecisa; l) Fornecer quaisquer informações falsas sobre
        você; m) Adulterar qualquer informação, como mensagem de e-mail ou
        cabeçalho com a logo.
        <p>
          <b>8. Das Sanções.</b>
        </p>
        Sem nenhum prejuízo das demais medidas legalmente cabíveis, a empresa
        poderá a qualquer momento suspender, cancelar ou advertir o acesso/conta
        do usuário, sem qualquer aviso e sem notificação, quando o mesmo fizer
        algo que: a) Que viole os termos presentes nesse documento; b) Que não
        respeite e descumpra os deveres de usuário; c) Que tiver qualquer
        comportamento fraudulento, de ofensa ou doloso em relação a terceiros.
        <p>
          <b>9. Responsabilidades e usos específicos. </b>
        </p>
        Além das responsabilidades previstas nos termos e condições de uso, o
        usuário concorda em cumprir as seguintes cláusulas: utilizar o site
        apenas para finalidades devidas e legítimas, sem qualquer desvirtuação
        da principal finalidade; fornecer informações completas, atualizadas,
        precisas e verdadeiras; publicar materiais ou comentários que você tenha
        total responsabilidade, direitos e licenças de uso; assumir riscos de
        qualquer utilização indevida do site ou dos conteúdos nele
        disponibilizados, informações divulgadas pelo usuário, seja por
        comentário ou de qualquer outra forma, proteção pelos seus dados de
        login; conteúdo ou atividades ilícitas praticadas por meio dessa página.
        É terminantemente proibido conteúdo de páginas externas, links para fins
        comerciais ou publicitários, informações ilícitas, vexatórias,
        violentas, polemicas, xenofóbicas, pornográficas, ofensivas e
        discriminatórias. O site também pode vir a apresentar links como botões
        de hiperlinks, que direcionam o acesso a sites de terceiros. Os sites de
        terceiros não têm nenhuma ligação com a plataforma Folheto na Mão, não
        sendo o proprietário da empresa Folheto na Mão responsável por qualquer
        informação, material ou conteúdo que contenha em sites ligados e não
        sendo responsáveis por nenhum conteúdo ou produto à venda em outros
        sites. O site ou qualquer outra plataforma não tem nenhuma associação,
        ou relacionamento, e muito menos alguma forma de patrocínio com o
        proprietário desta plataforma. A responsabilidade pelo acesso de sites
        que aparecem na página é exclusiva de sua responsabilidade. Utilize bem
        o seu discernimento e senso comum para acessar sites de terceiros que
        apareçam nos links e sempre confiram os termos de uso e políticas de
        privacidade. A empresa Folheto na Mão, através do site, não se
        responsabiliza por políticas de privacidade de terceiros, mesmo que
        sejam sites/ blogs ou links que aparecerem na plataforma.
      </S.Container>
    </Layout>
  );
}
