import { useEffect } from 'react';

import { Heading } from 'components';
import { Layout } from 'containers';

import * as S from './styles';

export function PrivacyPolicies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <S.Container>
        <Heading>Política de Privacidade</Heading>
        <p>
          Além do presente documento, o usuário deverá concordar com as
          disposições contidas na Política de Privacidade estabelecida pela
          plataforma e, neste ato, autoriza a empresa Folheto na Mão a coletar,
          armazenar, divulgar, utilizar, compartilhar com parceiros e tratar
          seus dados pessoais conforme previsto abaixo.
        </p>
        <div>
          <p>
            <b> 1. Objetivo.</b>
          </p>
          <p className="item">
            1.1. Esta Política de Privacidade (“Política”) objetiva informar
            sobre as práticas relativas à coleta, tratamento, armazenamento,
            controle e segurança de seus dados pessoais, respeitando os seus
            direitos como titular, atendendo aos princípios da objetividade e da
            boa-fé. A presente Política está em conformidade com a Lei nº
            13.709/2018 – Lei Geral de Proteção de Dados (LGPD) e alterações
            posteriores, que visa garantir os direitos fundamentais de
            liberdade, intimidade e de privacidade, buscando a transparência e o
            livre desenvolvimento da personalidade da pessoa natural.
          </p>
          <p className="item">
            1.2. Dados pessoais (“Dados”) são quaisquer informações relacionadas
            à pessoa natural identificada ou identificável. A LGPD dispõe sobre
            o direito ao acesso facilitado às informações acerca do tratamento
            de seus dados, que deverão ser disponibilizadas de forma clara,
            adequada e ostensiva, atendendo ao princípio de livre acesso aos
            seus dados pessoais.
          </p>
          <p className="item">
            1.3. Quando mencionarmos nesta Política “você”, “seu(s)”, “sua”,
            estamos nos referindo a você titular dos Dados que poderá ser: (i)
            visitante, (ii) cliente ou potencial cliente, (iii) fornecedor ou
            potencial fornecedor, (iv) terceiros em geral que entrem em contato
            através do nosso canal de atendimento e quando mencionarmos “Folheto
            na Mão”, “Empresa”, “nós”, “nossos” ou “conosco”, estamos nos
            referindo a empresa Folheto na Mão.
          </p>
          <p className="item">
            1.4. Por favor, leia integralmente o conteúdo antes de acessar as
            páginas ou nos fornecer informações.
          </p>
        </div>
        <div>
          <p>
            <b> 2. Qual a razão e quais são os dados coletados.</b>
          </p>
          <p className="item">
            2.1. A Folheto na Mão obtém informações de identificação fornecidas
            voluntariamente pelo titular dos Dados em nosso site (clientes,
            fornecedores, terceiros e sociedade em geral). O titular pode
            fornecer essas informações para cadastramento em determinadas áreas
            do site, como por exemplo, para tirar dúvidas, solicitar envio de
            newsletter, entre outros.
          </p>
          <p className="item">
            2.2. Não compartilhamos intencionalmente informações de
            identificação pessoal com terceiros, a menos que exigido por lei,
            determinação judicial ou que isso seja expressamente comunicado
            previamente a você.
          </p>
          <p className="item">
            2.3. Para o seu conhecimento e de maneira exemplificada, abaixo
            indicamos quais são os tipos de dados que poderemos coletar:
            Informações pessoais, tais como: nome, e-mail, senha e CEP.
          </p>
          <p className="item">
            2.4. Os dados coletados serão utilizados com a finalidade de
            identificação para sanar dúvidas, responder críticas, reclamações,
            sugestões ou solicitar envio de newsletter com promoções de
            parceiros em nosso portal www.folhetonamao.com.br.
          </p>
          <p className="item">
            2.5. Os dados mencionados acima são fornecidos pelo titular através
            do nosso website. Nós usamos esses dados de identificação apenas
            para os fins para os quais foram fornecidos.
          </p>
        </div>
        <div>
          <p>
            <b> 3. Como tratamos os dados.</b>
          </p>
          <p className="item">
            3.1. Os Dados capturados são utilizados nos sistemas corporativos da
            empresa Folheto na Mão, de acordo com o estabelecido nesta Política.
            Nós implementamos e mantemos processos e controles que visam
            proteger os dados que você fornecer e compartilhar conosco, com o
            objetivo de que eles sejam tratados com segurança, transparência e
            responsabilidade, assegurando os seus direitos como titular.
          </p>
          <p className="item">
            3.2. Seus Dados serão armazenados de forma a permitir a
            identificação dos assuntos a estes relacionados e apenas pelo
            período que se fizer necessário (armazenamento limitado).
          </p>
          <p className="item">
            3.3. O tratamento de seus Dados poderá ocorrer através das situações
            abaixo descritas, em conformidade com a Lei:
          </p>
          <p className="item">
            I - Consentimento – as finalidades do uso dos seus dados serão
            específicas e destacadas em seu consentimento, podendo você optar
            por aceitar ou não o uso dos dados para as finalidades informadas;
            Caso não aceite, a Folheto na Mão avaliará a viabilidade da
            continuidade do relacionamento com você.
          </p>
          <p className="item">
            II - Exercício regular de direitos – processos judiciais,
            administrativos ou arbitragens;
          </p>
          <p className="item">
            III - Legítimo interesse – na promoção de atividades da empresa
            Folheto na Mão ou em auditorias e investigações corporativas;
          </p>
          <p className="item">
            IV - Cumprimento de obrigação legal ou regulatória – procedimentos
            determinados por órgão regulador ou pela legislação que a empresa
            Folheto na Mão está exposta.
          </p>
          <p className="item">
            3.4. A empresa Folheto na Mão poderá compartilhar seus dados
            pessoais com autoridades competentes com a finalidade de cumprir
            medidas judiciais, processos judiciais e/ou administrativos,
            cooperar com investigações, incluindo outras requisições de
            autoridades e órgãos governamentais.
          </p>
          <p className="item">
            3.5. Nós poderemos armazenar os seus dados durante todo o período do
            relacionamento contratual, comercial, laboral ou outro que
            justifique o respectivo armazenamento. Após esse período, os dados
            serão descartados, com exceção dos dados que, em decorrência de
            obrigação legal, regulatória e outros interesses legítimos, precisem
            ser mantidos por período superior, em conformidade com a LGPD. Findo
            o prazo e a necessidade legal, serão excluídos com uso de métodos de
            descarte seguro.
          </p>
        </div>
        <div>
          <p>
            <b> 4. Os direitos do titular de dados.</b>
          </p>
          <p className="item">
            4.1. A LGPD assegura certos direitos à Pessoa Física quanto aos seus
            Dados. Os direitos do titular dos Dados são os seguintes:
          </p>
          <p className="item">
            I. Direito de confirmação da existência de tratamento aos seus
            Dados;
          </p>
          <p className="item">II. Direito de acesso aos seus Dados; </p>
          <p className="item">
            III. Direito de solicitar uma cópia de seus Dados em formato
            eletrônico;
          </p>
          <p className="item">
            IV. Direito de correção de Dados incompletos, inexatos ou
            desatualizados;
          </p>
          <p className="item">
            V. Direito de anonimização, exclusão ou bloqueio de seus Dados;
          </p>
          <p className="item">
            VI. Eliminação dos dados tratados com consentimento;
          </p>
          <p className="item">
            VII. Direito de opor-se ao tratamento realizado com dispensa de
            consentimento, caso não esteja em conformidade com a lei.
          </p>
          <p className="item">
            VIII. Direito à portabilidade de Dados mediante requisição expressa,
            solicitando quais foram as transmissões dos seus Dados para
            entidades públicas e privadas;
          </p>
          <p className="item">
            IX. Direito de informação das entidades públicas e privadas com as
            quais o controlador realizou uso compartilhado de Dados; X.
            Revogação do consentimento.
          </p>
          <p className="item">
            XI. Direito de se manifestar contra o controlador na ANPD e nos
            órgãos de defesa do consumidor.
          </p>
          <p className="item">
            4.2. Para quaisquer solicitações referentes aos seus dados, você
            deverá nos fornecer suas informações para identificação (Nome,
            e-mail, dentre outras informações que confirmem sua identidade),
            através do e-mail encarregado@folhetonamao.com.br, que será
            respondido dentro de até 15 (quinze) dias, ou poderá ser endereçado
            para: empresa Folheto na Mão, Rua Vitório Zambom, nº. 33, Jardim
            Santa Cruz, CEP 13.601-216, na cidade Araras/SP
          </p>
        </div>
        <div>
          <p>
            <b> 5. Utilização de cookie.</b>
          </p>
          <p className="item">
            5.1. Um cookie é um pequeno arquivo de texto, que quando o usuário
            acessa o site, é solicitado em seu dispositivo para que o navegador
            lembre informações sobre o mesmo. Os cookies são definidos por nossa
            equipe, podendo ser cookies de domínio do site, publicidade ou
            marketing.
          </p>
          <p className="item">
            5.2. Nós utilizamos cookies para otimizar e analisar o tráfego do
            nosso website. O principal propósito de nossos cookies é melhorar a
            experiência de pesquisa, como por exemplo, lembrar suas preferências
            (linguagem e país) enquanto pesquisa e também para as visitas
            futuras.
          </p>
          <p className="item">
            5.3. As informações coletadas pelos cookies também contribuem para
            que nós melhoremos o nosso website, estimando números e padrões de
            uso, a compatibilidade do website para os interesses do usuário,
            pesquisas rápidas etc.
          </p>
        </div>
        <div>
          <p>
            <b> 6. Segurança dos dados pessoais.</b>
          </p>
          <p className="item">
            6.1. Segurança da informação e Confidencialidade.
          </p>
          <p className="item">
            6.1.1. A empresa Folheto na Mão mantém mecanismos de tecnologia da
            informação alinhados aos padrões técnicos e regulatórios de mercado
            visando assegurar a integridade e proteção dos seus Dados, com
            políticas, soluções de segurança nos hardwares e softwares,
            relatórios de monitoramento e auditorias internas que ajudam a
            identificação e mitigação de potenciais riscos no processo. Ademais,
            a empresa Folheto na Mão regularmente revisa suas políticas
            internas, visando assegurar o cumprimento de medidas que garantam a
            segurança e a confidencialidade de seus dados.
          </p>
          <p className="item">6.2. Transparência. </p>
          <p className="item">
            6.2.1. Você, na qualidade de titular dos Dados, tem o direito de
            saber quais são os procedimentos que a empresa Folheto na Mão adotou
            no tratamento destes, através de informações transparentes sobre a
            finalidade do tratamento, a origem dos dados e os critérios
            utilizados. A empresa comunicará os usuários caso ocorra qualquer
            alteração no procedimento de tratamento dos seus Dados.
          </p>
          <p className="item">6.3. Notificação de incidente de segurança. </p>
          <p className="item">
            6.3.1. Caso a empresa Folheto na Mão tome conhecimento de um
            incidente de segurança, que possa acarretar em qualquer violação à
            segurança dos sistemas de informação, incluindo invasões, vazamentos
            de dados ou qualquer outro, a empresa, em prazo razoável, irá
            notificar a Autoridade Nacional de Proteção de Dados (ANPD), e se
            aplicável, notificará o titular com informações sobre os dados
            afetados, medidas técnicas e de segurança utilizadas para proteção
            de dados, de acordo com o disposto nos artigos 48, § 1º, da LGPD.
          </p>
        </div>
        <div>
          <p>
            <b> 7. Contato (canal para comunicações sobre seus Dados).</b>
          </p>
          <p className="item">
            7.1. Para exercer os seus direitos de titular, para sanar dúvidas ou
            dar sugestões sobre a presente Política, a Folheto na Mão
            disponibiliza o contato com o encarregado de proteção de dados, nos
            termos do artigo 41 da LGPD: Nome do encarregado - Daniela Vancini
            E-mail: encarregado@folhetonamao.com.br
          </p>
        </div>
        <div>
          <p>
            <b> 8. Atualizações da Política de Privacidade.</b>
          </p>
          <p className="item">
            8.1. A presente Política foi aprovada e passou a vigorar a partir de
            15 de Setembro de 2022, por prazo indeterminado, até que haja
            deliberação em sentido contrário, podendo ser consultada no site da
            empresa: https://www.folhetonamao.com.br
          </p>
          <p className="item">
            8.2. Esta Política poderá ser atualizada e quaisquer atualizações
            serão feitas em conformidade com a legislação brasileira. Sendo
            assim, é recomendado o acesso e aceite periódico a esta política.
          </p>
        </div>
        <div>
          <p>
            <b> 9. Do Foro</b>
          </p>
          <p className="item">
            9.1. Em caso de eventuais litígios decorrentes de qualquer
            controvérsia será aplicado o direito brasileiro e a legislação
            nacional aplicável, onde o foro eleito será o da comarca da empresa
            Folheto na Mão, ou seja, de Araras/SP.
          </p>
        </div>
      </S.Container>
    </Layout>
  );
}
