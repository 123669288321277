import React, { createContext, useCallback, useState, useContext } from 'react';

import { message } from 'antd';
import axios from 'axios';
import api from 'services/api';

interface User {
  id: string;
  name: string;
  country: string;
  state: string;
  city: string;
  email: string;
  created_at: Date;
  updated_at: Date;
}

interface UserState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface RegisterCredentials {
  email: string;
  password: string;
  confirmPassword: string;
}

interface UserContextData {
  isLoading: boolean;
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  createAccount(credentials: RegisterCredentials): void;
  resetPassword(email: string): void;
  createNewPassword(token: string, password: string): void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<UserState>(() => {
    const token = localStorage.getItem('@folhetonamao:token');
    const user = localStorage.getItem('@folhetonamao:user');

    if (token && user) {
      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as UserState;
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSignOut = useCallback(async () => {
    try {
      await api.get('user/logout');

      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
    } catch (err) {
      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
    }
  }, []);

  const handleSignIn = useCallback(async ({ email, password }) => {
    setIsLoading(true);
    try {
      const authResponse = await api.post('user/auth', {
        email,
        password,
      });

      const { token } = authResponse.data;

      localStorage.setItem('@folhetonamao:token', token);

      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      const userResponse = await api.get('user');

      const user = userResponse.data;

      localStorage.setItem('@folhetonamao:user', JSON.stringify(user));

      setData({ token, user });
    } catch (err) {
      message.error('Usuário ou senha inválidos');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCreateAccount = useCallback(async ({ name, email, password }) => {
    try {
      const { data } = await api.post('user', {
        name,
        state: localStorage.getItem('@folhetonamao:state'),
        city: localStorage.getItem('@folhetonamao:city'),
        email,
        password,
      });

      if (data.id) {
        message.success('Um e-mail de confirmação foi enviado para você!');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 422 && err.response?.data.email) {
          message.error('Esse e-mail já está sendo utilizado!');
        } else if (
          err.response?.status === 422 &&
          err.response?.data.password
        ) {
          message.error(err.response?.data.password[0]);
        } else {
          message.error('Ocorreu um erro ao tentar criar sua conta!');
        }
      } else {
        message.error('Ocorreu um erro ao tentar cadastrar o usuário!');
      }
    }
  }, []);

  const handleForgotPassword = useCallback(async email => {
    const { status } = await api.post('user/password/forgot', { email });

    if (status === 201) {
      window.location.href = '/redefinir-senha/enviado';
    }
  }, []);

  const handleCreateNewPassword = useCallback(async (token, password) => {
    const { status } = await api.put('user/password/forgot', {
      token,
      password,
    });

    if (status === 200) {
      window.location.href = '/nova-senha/sucesso';
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoading,
        user: data.user,
        signIn: handleSignIn,
        signOut: handleSignOut,
        resetPassword: handleForgotPassword,
        createNewPassword: handleCreateNewPassword,
        createAccount: handleCreateAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): UserContextData {
  const context = useContext(UserContext);

  return context;
}

export { UserProvider, useUser };
