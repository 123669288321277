import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Result } from 'antd';
import { Layout } from 'containers';
import { useUser } from 'hooks';

export function ResetPasswordSuccess() {
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, [user]);

  return (
    <Layout>
      <Result
        status="success"
        title="Senha redefinida com sucesso!"
        subTitle="Faça login para utilizar o Folheto na mão."
      />
    </Layout>
  );
}
