import { ButtonProps } from './interfaces';
import * as S from './styles';

export function Button({
  children,
  buttonType = 'primary',
  size = 'medium',
  onlyIcon = false,
  icon,
  fullWidth = false,
  iconDirection = 'left',
  isLoading = false,
  error = false,
  ...props
}: ButtonProps) {
  return (
    <S.Wrapper
      buttonType={buttonType}
      size={size}
      onlyIcon={onlyIcon}
      hasIcon={!!icon}
      iconDirection={iconDirection}
      fullWidth={fullWidth}
      isLoading={isLoading}
      error={error}
      {...props}
    >
      {isLoading ? (
        <div data-testid="wrapper-loading">
          <span>Carregando...</span>
        </div>
      ) : (
        <>
          {icon}
          {!onlyIcon && <span>{children}</span>}
        </>
      )}
    </S.Wrapper>
  );
}
