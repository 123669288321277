import { secondaryColors, breakpoints } from 'common/styles/theme';
import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;

  h3 {
    margin-bottom: 0;

    @media (max-width: ${breakpoints.small}) {
      font-size: 2.4rem;
    }
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const SectionSubtitle = styled.span`
  display: block;
  font-size: 2rem;
  color: ${secondaryColors.grey200};

  @media (max-width: ${breakpoints.small}) {
    font-size: 1.3rem;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3.2rem;
  max-width: 49.6rem;

  input {
    margin-bottom: 2.4rem;
  }

  button {
    max-width: 26.1rem;
    height: 5rem;
    border-radius: 0;
    margin-bottom: 24px;
  }
`;
